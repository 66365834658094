import React from 'react';
import { Permissions } from '../../constants/entities/user';

export const VirtualEvalationListingPath = '/listing/';

const virtualEvaluationRoutes = [
  {
    path: VirtualEvalationListingPath,
    exact: true,
    component: React.lazy(() => import('modules/virtual-eval-listing')),
    permission: Permissions.VirtualEvaluation.GetList,
  },
  {
    path: '/listing-create/',
    exact: true,
    component: React.lazy(() => import('modules/virtual-evaluation/Create')),
    permission: Permissions.VirtualEvaluation.Create,
  },
  {
    path: '/listing/:id/',
    exact: true,
    component: React.lazy(() => import('modules/virtual-evaluation/View')),
    permission: Permissions.VirtualEvaluation.GetOne,
  },

  {
    path: '/listing/:id/edit/',
    exact: true,
    component: React.lazy(() => import('modules/virtual-evaluation/Edit')),
    permission: Permissions.VirtualEvaluation.Update,
  },
  {
    path: '/listing/:id/appraise/',
    exact: true,
    component: React.lazy(() => import('modules/virtual-evaluation/Appraise')),
    permission: Permissions.VirtualEvaluation.Appraise,
  },
  {
    path: '/listing/:id/appraisalValue/',
    exact: true,
    component: React.lazy(
      () => import('modules/virtual-evaluation/Appraise/appraisalValue')
    ),
    permission: Permissions.VirtualEvaluation.Appraise,
  },
];

export default virtualEvaluationRoutes;
